import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "lib/redux";

export const selectConsumerExpertProfileState = ({ dashboard }: RootState) =>
  dashboard.consumer.expertProfile.state;

interface IInitialState {
  isMatchPending: boolean;
}

const initialState: IInitialState = {
  isMatchPending: false,
};

const consumerExpertProfileStateSlice = createSlice({
  name: "dashboard/consumer/expert-profile/state",
  initialState,
  reducers: {
    setConsumerExpertProfileState: (state, action: PayloadAction<Partial<IInitialState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setConsumerExpertProfileState } = consumerExpertProfileStateSlice.actions;

export default consumerExpertProfileStateSlice.reducer;
