import { getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import {
  IFindExpertsContent,
  IFindExpertsEditContent,
  IFindExpertsGetStartedContent,
  IFindExpertsSelectContent,
} from "models/dashboard/consumer/find-experts";
import { formPopulate } from "models/form";
import { imagePopulate } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getConsumerFindExpertsContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-find-expert`, {
      params: {
        populate: {
          infoSection: {
            populate: { items: true },
          },
          actions: {
            populate: { button: true },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IFindExpertsContent>)
    .catch(getError);
};

export const getConsumerFindExpertsGetStartedContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-find-expert-get-started`, {
      params: {
        populate: {
          steps: {
            populate: {
              forms: formPopulate["form"],
            },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IFindExpertsGetStartedContent>)
    .then((data) => {
      for (let s of data.steps) {
        for (let form of s.forms) {
          transformForm({ form }, ["form"]);
        }
      }
      return data;
    })
    .catch(getError);
};

export const getConsumerFindExpertsEditContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-find-expert-edit`, {
      params: {
        populate: {
          steps: {
            populate: {
              forms: formPopulate["form"],
            },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IFindExpertsEditContent>)
    .then((data) => {
      for (let s of data.steps) {
        for (let form of s.forms) {
          transformForm({ form }, ["form"]);
        }
      }
      return data;
    })
    .catch(getError);
};

export const getConsumerFindExpertsSelectContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-find-expert-select`, {
      params: {
        populate: {
          expertCard: { populate: { items: true, buttons: true } },
          pendingRequest: { populate: { icon: imagePopulate } },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IFindExpertsSelectContent>)
    .catch(getError);
};
