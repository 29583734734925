import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { AppDispatch, RootState } from "lib/redux";
import { IUser, IUserTransformed } from "models/dashboard/shared";
import { RequestMeta } from "models/shared";
import { HYDRATE } from "next-redux-wrapper";
import { getIsSubscribedNewsletter, getUserProfile } from "rest-api/user/profile";
import { transformEntityResponse } from "utils/format";
import { getFieldOptions, selectFieldOptions, selectFieldOptionsMeta } from "./fieldOptionsSlice";

export const getProfile = createAsyncThunk<
  { data: IUser; transformedData: IUserTransformed },
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: ErrorResult }
>("dashboard/profile/getProfile", async (_, { dispatch, getState, rejectWithValue }) => {
  const result = await getUserProfile();

  if (isError(result)) {
    return rejectWithValue(result);
  }

  const newsletterSubscribedResult = await getIsSubscribedNewsletter();
  const newsletterSubscribedError = isError(newsletterSubscribedResult);
  const newsletterSubscribed = newsletterSubscribedError ? false : newsletterSubscribedResult;
  result.newsletterSubscribed = newsletterSubscribed;

  await dispatch(getFieldOptions());
  const { requestStatus, error } = selectFieldOptionsMeta(getState());
  if (requestStatus === "rejected") return rejectWithValue({ error } as ErrorResult);
  const fieldOptions = selectFieldOptions(getState());
  const transformedData = transformEntityResponse<IUserTransformed>(result, fieldOptions);

  return { data: result, transformedData };
});

export const selectProfile = ({ dashboard }: RootState) => dashboard.profile;

export const selectProfileData = ({ dashboard }: RootState) => dashboard.profile.data;

export const selectProfileDataTransformed = ({ dashboard }: RootState) =>
  dashboard.profile.transformedData;

export const selectProfileMeta = ({ dashboard }: RootState) => dashboard.profile.meta;

interface IInitialState extends RequestMeta {
  data: IUser;
  transformedData: IUserTransformed;
}

const initialData: IUser & IUserTransformed = {
  id: "",
  questionnaireComplete: false,
  role: { name: null },
  confirmed: true,
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  locations: [],
  suburbs: [],
  commercialEmailsUnsubscribed: false,
  reviews: [],
};

const initialState: IInitialState = {
  data: initialData,
  transformedData: initialData,
  meta: {
    requestStatus: "initial",
  },
};

const profileSlice = createSlice({
  name: "dashboard/profile",
  initialState,
  reducers: {
    setProfile: (
      state,
      { payload }: PayloadAction<{ data: IUser; transformedData: IUserTransformed }>,
    ) => {
      state.data = { ...state.data, ...payload.data };
      state.transformedData = { ...state.transformedData, ...payload.transformedData };
      state.meta.requestStatus = "fulfilled";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.meta = { requestStatus: "pending" };
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.transformedData = payload.transformedData;
        state.meta.requestStatus = "fulfilled";
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.meta = { requestStatus: "rejected", ...action.payload };
      })
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IInitialState, typeof HYDRATE>) => action.payload,
      );
  },
});

export const { setProfile } = profileSlice.actions;
export default profileSlice.reducer;
