import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorResult, isError } from "lib/axios/error";
import { RootState } from "lib/redux";
import { IFindExpertsSelectContent } from "models/dashboard/consumer/find-experts";
import { HYDRATE } from "next-redux-wrapper";
import { getConsumerFindExpertsSelectContentReq } from "rest-api/dashboard/consumer/find-expert";

export const getConsumerFindExpertsSelectContent = createAsyncThunk<
  typeof initialState,
  void,
  { rejectValue: ErrorResult }
>(
  "dashboard/consumer/findExperts/select/content/getConsumerFindExpertsSelectContent",
  async (_, { rejectWithValue }) => {
    const result = await getConsumerFindExpertsSelectContentReq();

    if (isError(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const selectConsumerFindExpertsSelectContent = ({ dashboard }: RootState) =>
  dashboard.consumer.findExperts.select.content;

const initialState: IFindExpertsSelectContent = {
  headerTitle: "",
  previousText: "",
  expertCard: {
    items: [],
    buttons: [],
  },
  pendingRequest: {
    title: "",
    content: "",
    icon: { url: "", alternativeText: "" },
  },
};

const contentSlice = createSlice({
  name: "dashboard/consumer/findExperts/select/content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getConsumerFindExpertsSelectContent.fulfilled, (_, action) => action.payload)
      .addCase(
        HYDRATE,
        (_, action: PayloadAction<IFindExpertsSelectContent, typeof HYDRATE>) => action.payload,
      );
  },
});

export default contentSlice.reducer;
