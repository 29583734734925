import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import merge from "lodash/merge";
import { IReferral } from "models/dashboard/consumer/referral";
import { IUser } from "models/dashboard/shared";
import { EntityListParams, imagePopulate, imagePopulateWithFormats } from "models/shared";
import { transformResponse } from "utils/format";

export async function getReferralsReq(
  currentUserId: string,
  params: EntityListParams,
): Promise<IReferral[] | ErrorResult> {
  return axiosInstance
    .get<{ data: { attributes: IReferral }[] }>("/api/referrals", {
      params: {
        filters: { buyerSeller: { id: "me" } },
        sort: "updatedAt:desc",
        populate: {
          professional: {
            fields: ["id", "firstName", "lastName"],
            populate: {
              profilePicture: imagePopulateWithFormats,
              reviews: {
                populate: true,
                filters: {
                  $and: [
                    {
                      $or: [{ hidden: { $ne: true } }, { author: { id: currentUserId } }],
                    },
                    { referral: { id: { $notNull: true } } },
                  ],
                },
              },
            },
          },
          relatedReferrals: { fields: ["id"] },
          review: { images: imagePopulate },
        },
        ...params,
      },
      withCredentials: true,
    })
    .then(({ data }) => data)
    .then(({ data }) => transformResponse<IReferral[]>(data))
    .catch(getError);
}

export async function getReferralReq(id: string): Promise<IReferral | ErrorResult> {
  return axiosInstance
    .get<{ data: { attributes: IReferral } }>(`/api/referrals/${id}`, {
      params: {
        populate: {
          professional: { fields: ["firstName", "lastName"] },
        },
      },
      withCredentials: true,
    })
    .then(({ data }) => data)
    .then(({ data }) => transformResponse<IReferral>(data))
    .catch(getError);
}

export async function getPotentiallyMatchedExpertsReq(id: string): Promise<IUser[] | ErrorResult> {
  return axiosInstance
    .get<IUser[]>(`/api/referrals/${id}/find-potential-professionals`, {
      params: {
        fields: ["firstName", "lastName"],
        populate: {
          profilePicture: imagePopulateWithFormats,
          professional: { fields: ["job", "yearsOfExperience"] },
          reviews: { populate: true },
        },
      },
      withCredentials: true,
    })
    .then(({ data }) => data)
    .then(transformResponse<IUser[]>)
    .catch(getError);
}

export async function createManyReferrals(data: {
  experts: string[];
  expertQuestions: object;
}): Promise<IReferral[] | ErrorResult> {
  return axiosInstance
    .post<IReferral[]>(
      "/api/referrals/many",
      { data },
      {
        params: {
          populate: {
            potentiallyMatchedProfessionals: true,
          },
        },
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .catch(getError);
}

export async function matchProfessionalToReferrals(data: {
  referralId: string;
  professionalId: string;
}): Promise<"ok" | ErrorResult> {
  return axiosInstance
    .post<"ok">(
      "/api/referrals/match",
      { data },
      {
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .catch(getError);
}

export async function unmatchProfessionalToReferrals(data: {
  referralId: string;
}): Promise<"ok" | ErrorResult> {
  return axiosInstance
    .post<"ok">(
      "/api/referrals/unmatch",
      { data },
      {
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .catch(getError);
}

export async function requestAnother(
  id: string,
  data?: {
    expertQuestions: object;
  },
): Promise<IReferral | ErrorResult> {
  return axiosInstance
    .post<IReferral>(
      `/api/referrals/request-another/${id}`,
      { data },
      {
        params: {
          populate: {
            potentiallyMatchedProfessionals: true,
          },
        },
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .catch(getError);
}

export async function getRelevantReferralsReq(
  status: IReferral["acquisitionStatus"],
  params: EntityListParams | any,
): Promise<{ data: IReferral[]; meta: Pick<EntityListParams, "pagination"> } | ErrorResult> {
  return axiosInstance
    .get<{ data: IReferral[]; meta: Pick<EntityListParams, "pagination"> }>(
      `/api/referrals/relevant/${status}`,
      {
        params: merge(
          {
            sort: "updatedAt:desc",
            populate: {
              buyerSeller: { populate: ["buyerSeller"] },
            },
          },
          params,
        ),
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .then(({ data, meta }) => ({ data: transformResponse<IReferral[]>(data), meta }))
    .catch(getError);
}

export async function updateReferralAcquisitionStatus(
  id: string,
  data?: {
    acquisitionStatus: string;
  },
): Promise<{ id: string }[] | ErrorResult> {
  return axiosInstance
    .put<{ id: string }[]>(
      `/api/referrals/${id}`,
      { data },
      {
        withCredentials: true,
      },
    )
    .then(({ data }) => data)
    .catch(getError);
}
