import { combineReducers } from "@reduxjs/toolkit";
import content from "./contentSlice";
import leaveReviewContent from "./leaveReviewContentSlice";
import leaveReviewState from "./leaveReviewStateSlice";
import profile from "./profileSlice";
import reportProfileContent from "./reportProfileContentSlice";
import reviewsFilterState from "./reviewsFilterStateSlice";
import state from "./stateSlice";

const reducer = combineReducers({
  content,
  profile,
  state,
  reviewsFilterState,
  leaveReviewContent,
  leaveReviewState,
  reportProfileContent,
});

export default reducer;
