import { ErrorResult, getError } from "lib/axios/error";
import axiosInstance from "lib/axios/instance";
import {
  IExpertProfileContent,
  IReportProfileContent,
} from "models/dashboard/consumer/expert-profile";
import { IUser } from "models/dashboard/shared";
import { formPopulate } from "models/form";
import { imagePopulate, imagePopulateWithFormats } from "models/shared";
import { transformForm, transformResponse } from "utils/format";

export const getConsumerExpertProfileContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-expert-profile`, {
      params: {
        populate: {
          profileSection: {
            populate: {
              completionFields: formPopulate["form"]["populate"]["fields"],
              items: true,
            },
          },
          aboutSection: {
            populate: { values: true },
          },
          detailsSection: {
            populate: { values: true },
          },
          reviewsSection: {
            populate: "*",
          },
          previousWorkSection: {
            populate: "*",
          },
          getMatchedSection: {
            populate: { button: true },
          },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IExpertProfileContent>)
    .then((data) =>
      transformForm(data, [{ path: "profileSection", fieldsKey: "completionFields" }]),
    )
    .catch(getError);
};

export async function getExpertProfileReq(id: string): Promise<IUser | ErrorResult> {
  return axiosInstance
    .get<IUser>(`/api/users/find-professional/${id}`, {
      params: {
        populate: {
          profilePicture: imagePopulateWithFormats,
          professional: {
            populate: {
              previousWork: {
                populate: {
                  images: imagePopulateWithFormats,
                },
              },
            },
          },
          matchedReferrals: {
            fields: ["id"],
          },
          reviews: {
            sort: { createdAt: "desc" },
            populate: {
              images: imagePopulateWithFormats,
              referral: { populate: { buyerSeller: { populate: "*" } } },
            },
          },
        },
      },
      withCredentials: true,
    })
    .then(({ data }) => data)
    .catch(getError);
}

export const getConsumerReportProfileContentReq = () => {
  return axiosInstance
    .get(`/api/buyer-seller-report-profile`, {
      params: {
        populate: {
          ...formPopulate,
          contactUsButton: { populate: true },
          successSection: { populate: { icon: imagePopulate, buttons: true } },
        },
      },
    })
    .then(({ data }) => data)
    .then(transformResponse<IReportProfileContent>)
    .then((data) => transformForm(data, ["form"]))
    .catch(getError);
};

export async function reportProfile(data: {
  reportProfileReason: string;
  reportProfileContent: string;
  professional: string;
}): Promise<{ id: string } | ErrorResult> {
  return axiosInstance
    .post<ReturnType<typeof reportProfile>>(
      `/api/report-profiles`,
      { data },
      { withCredentials: true },
    )
    .then(({ data }) => data)
    .then(transformResponse<{ id: string }>)
    .catch(getError);
}

export async function deleteReportProfile(id: string): Promise<{ id: string } | ErrorResult> {
  return axiosInstance
    .delete<ReturnType<typeof deleteReportProfile>>(`/api/report-profiles/${id}`, {
      withCredentials: true,
    })
    .then(({ data }) => data)
    .then(transformResponse<{ id: string }>)
    .catch(getError);
}
